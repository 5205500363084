import axios from "utils/axios";
import { setAlert } from "./alert";
import axiosifs from "utils/axiosifs";

    export const  downloadingReceipt = (body: any,dispatch, setIsLoading?: any) =>
        async () => {
        try {
            if(setIsLoading)
                setIsLoading(true);
            const response : any = await axios.post("feereceipt/download", body, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: 'blob'       
            })
            
            const downloadUrl = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = downloadUrl;
            a.download = 'file.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        } catch {
            dispatch(setAlert({
                msg: "Failed to download the receipt!",
                alertType: "danger",
                status: 500
            }))
            return;
        } finally {
            if(setIsLoading)
                setIsLoading(false);
        }
        
    }
    export const  printingReceipt = (body: any, dispatch: any, setIsLoading?: any) =>
        async () => {
        try {
            if(setIsLoading)
                setIsLoading(true);
            const response : any = await axios.post("feereceipt/download", body, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: 'blob'       
            })
            
            var file = new Blob([response.data], {type: 'application/pdf'});
            const blobURL = window.URL.createObjectURL(file);
            var a: HTMLAnchorElement = document.createElement('a');
            a.href = blobURL;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        } catch {
            dispatch(setAlert({
                msg: "Failed to print the receipt!",
                alertType: "danger",
                status: 500
            }))
            return;
        } finally {
            if(setIsLoading)
                setIsLoading(false);
        }
        
    }

    export const downloadingAdmissionForm = (body: any, dispatch, setIsLoading?: any, type ?: string) =>
    async () => {
    try {
        if(setIsLoading)
            setIsLoading(true);
        let url = "student/download";
        if(type === "EMPTY"){
            url = "student/downloadForm"
        }
        const response : any = await axios.post(url, body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
        
        const downloadUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'admissionform.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    } catch {
        dispatch(setAlert({
            msg: "Failed to download the receipt!",
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }
    
    }

    export const  printingAdmissionForm = (body: any, dispatch: any, setIsLoading?: any) =>
        async () => {
        try {
            if(setIsLoading)
                setIsLoading(true);
            const response : any = await axios.post("student/download", body, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: 'blob'       
            })
            
            var file = new Blob([response.data], {type: 'application/pdf'});
            const blobURL = window.URL.createObjectURL(file);
            var a: HTMLAnchorElement = document.createElement('a');
            a.href = blobURL;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        } catch {
            dispatch(setAlert({
                msg: "Failed to print the receipt!",
                alertType: "danger",
                status: 500
            }))
            return;
        } finally {
            if(setIsLoading)
                setIsLoading(false);
        }
        
    }
    export const downloadingExpenseVoucher = (body: any, dispatch, setIsLoading?: any, type ?: string) =>
    async () => {
    try {
        if(setIsLoading)
            setIsLoading(true);
        let url = "expenses/downloadVoucher";
        if(type === "EMPTY"){
            url = "student/downloadVoucherForm"
        }
        const response : any = await axios.post(url, body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
        
        const downloadUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'admissionform.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    } catch {
        dispatch(setAlert({
            msg: "Failed to download the voucher!",
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }
    
    }

    export const  printingExpenseVoucher = (body: any, dispatch: any, setIsLoading?: any) =>
        async () => {
        try {
            if(setIsLoading)
                setIsLoading(true);
            const response : any = await axios.post("expenses/downloadVoucher", body, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: 'blob'       
            })
            
            var file = new Blob([response.data], {type: 'application/pdf'});
            const blobURL = window.URL.createObjectURL(file);
            var a: HTMLAnchorElement = document.createElement('a');
            a.href = blobURL;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        } catch {
            dispatch(setAlert({
                msg: "Failed to print the voucher!",
                alertType: "danger",
                status: 500
            }))
            return;
        } finally {
            if(setIsLoading)
                setIsLoading(false);
        }
        
    }
    export const getlogo =
        (id: any) =>
            async () => {
                const config: any = {
                    header: {
                        "Content-Type": "application/json",
                    },
                };
    
                try {
                    const { data } = await axiosifs.get(`media/file?path=${id}`, config);
                    return data
                } catch (error: any) {
    
                }
                // finally {
                //   setSubmitting(false);
                // }
            };

            

export const  getStudentIdCards = (body: any,dispatch, setIsLoading?: any) =>
    async () => {
        console.log('run')
    try {
        if(setIsLoading)
            setIsLoading(true);
        const response : any = await axios.post("/student/getStudentIdCards", body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
        
        const downloadUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'IdCards.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        dispatch(
            setAlert({
                msg: "Id Card(s) Downloaded Successful!",
                status: 200,
                alertType: "success",
            })
        );
    } catch (error){
        const message = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong when downloading the Id Cards!";
        dispatch(setAlert({
            msg: message,
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }
    
}

export const  getStaffIdCards = (body: any,dispatch, setIsLoading?: any) =>
    async () => {
    try {
        if(setIsLoading)
            setIsLoading(true);
        const response : any = await axios.post("/staff/getStaffIdCards", body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
        
        const downloadUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'IdCards.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        dispatch(
            setAlert({
                msg: "Id Card(s) Downloaded Successful!",
                status: 200,
                alertType: "success",
            })
        );
    } catch (error){
        const message = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong when downloading the Id Cards!";
        dispatch(setAlert({
            msg: message,
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }
    
    }

export const downloadingQrCode = (body: any, dispatch, setIsLoading?: any) =>
   
    async () => {
       
        try {
        if(setIsLoading)
            setIsLoading(true);
        const response : any = await axios.post("dynamicforms/downloadQR", body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
    
        const fileName = `${body.branch}-${body.type || 'file'}.pdf`;
        const downloadUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    } catch {
        dispatch(setAlert({
            msg: "Failed to download the form!",
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }     
}
    
export const  printingQrCode = (body: any, dispatch: any, setIsLoading?: any) =>
    async () => {
    try {
        if(setIsLoading)
            setIsLoading(true);
        const response : any = await axios.post("dynamicforms/downloadQR", body, {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: 'blob'       
        })
            
        var file = new Blob([response.data], {type: 'application/pdf'});
        const blobURL = window.URL.createObjectURL(file);
        var a: HTMLAnchorElement = document.createElement('a');
        a.href = blobURL;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
    } catch {
        dispatch(setAlert({
            msg: "Failed to print the form!",
            alertType: "danger",
            status: 500
        }))
        return;
    } finally {
        if(setIsLoading)
            setIsLoading(false);
    }
        
}   