import axios from "utils/axios";
import { setUserAuthToken } from "utils/headers";

export async function promoteDemoteStudents(data) {
  try {
    return await axios.post(`student/promote`, data);
  } catch (error) {
    return error?.response;
  }
}
export async function promoteDemoteStudentsFinancially(data) {
  try {
    if (localStorage.user__token)
      setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
    return await axios.post(`student/promote/financial`, data);
  } catch (error) {
    return error?.response;
  }
}

// API: v1/student/shuffle
export async function shuffleStudents(data) {
  try {
    if (localStorage.user__token)
      setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
    return await axios.post(`student/shuffle`, data);
  } catch (error) {
    return error?.response;
  }
}

// API: v1/student/getJourneyByTypes
export async function getShuffleStudentJourney(data) {
  try {
    return await axios.post(`student/getJourneyByTypes`, data);
  } catch (error) {
    return error?.response;
  }
}

// API: v1/student/getStudentsWithFees
export async function getStudentsWithFeeInfo(data) {
  try {
    return await axios.post(`student/getStudentsWithFees`, data);
  } catch (error) {
    return error?.response;
  }
}

// API : v1/student/search
export async function searchStudents(data, setIsLoading?: any) {
  try {
    return await axios.post(`student/search`, data);
  } catch (error) {
    return error?.response;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}

// API : v1/student/shiftBranch
export async function shiftBranch(data) {
  try {
    return await axios.post(`student/shiftBranch`, data);
  } catch (error) {
    return error?.response;
  }
}

// API : v1/student/globalSearch
export async function searchStudentsGlobal(data) {
  try {
    return await axios.post(`student/globalSearch`, data);
  } catch (error) {
    return error?.response;
  }
}

// API : v1/student/getStudents
export async function getStudentsandStaff(data) {
  try {
    return await axios.post(`student/getStudents`, data);
  } catch (error) {
    return error?.response;
  }
}
// API : v1/student/getStudentFeeCounts
export async function getStudentFeeCounts(data) {
  try {
    return await axios.post(`student/getStudentFeeCounts`, data);
  } catch (error) {
    return error?.response;
  }
}
// API : v1/studentSubject/assignbulksubject
export async function assignSubjectsToStudents(data) {
  try {
    return await axios.post(`studentSubject/assignbulksubject`, data);
  } catch (error) {
    return error;
  }
}
// API : v1/studentSubject/unassignbulksubject
export async function unassignStudentSubject(data) {
  try {
    return await axios.post(`studentSubject/unassignbulksubject`, data);
  } catch (error) {
    return error;
  }
}

//Attendance Record
export async function getAttendanceRecord(data) {
  try {
    return await axios.post(`sessionattendance/getAttendanceByMonth`, data);
  } catch (error) {
    return error;
  }
}