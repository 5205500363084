import { aW } from "@fullcalendar/core/internal-common";
import { setUserAuthToken } from 'utils/headers';
import axios from "utils/axios";

// API: v1/admissiontype/get
export async function getAdmissionTypes(data) {
    try {
        return await axios.get(`admissiontype/get`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/orgsource/getOrgSources
export async function getOrgSoruces(data) {
    try {
        return await axios.post(`orgsource/getOrgSources`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/board/getboards
export async function getBoards(data) {
    try {
        return await axios.get(`board/getboards`, data);

    } catch (error) {
        return error;
    }
}

// API: v1/enquiry/getEnquirys
export async function getEnquiries(data) {
    try {
        return await axios.post(`enquiry/getEnquirys`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/enquiry/search
export async function searchEnquiries(data) {
    try {
        return await axios.post(`enquiry/search`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/application/getApplications
export async function getApplicants(data) {
    try {
        return await axios.post(`application/getApplications`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/student/getEnrolledStudents
export async function getEnrolledStudents(data) {
    try {
        return await axios.post(`student/getEnrolledStudents`, data);

    } catch (error) {
        return error;
    }
}

// API:v1/admissionsetting/initialsetup/create

export async function addAdmissionNumberSettings(data) {

    try {
       let response= await axios.post(`admissionsetting/initialsetup/create`, data)
       return response;

    } catch (error) {
        return error
    }
}

// API:v1/enrollmentcode/initialsetup/create

export async function createEnrollmentCode(data) {

    try {
    let response= await axios.post(`enrollmentcode/initialsetup/create`, data)
return response;
    } catch (error) {
        return error
    }
}


// API:v1/enquirystage/create
export async function addEnquiryStage(data) {

    try {
      let response=  await axios.post(`enquirystage/initialsetup/create`, data);
      return response;

    } catch (error) {
        return error
    }
}


//API:v1/orgsource/create

export async function addOrgSource(data) {

    try {
      let response=  await axios.post(`orgsource/initialsetup/create`, data);
      return response;


    } catch (error) {
        return error
    }
}

// API:v1/applicationsetting/initialsetup/create


export async function createAplicationNumberSettings(data) {

    try {
       let response= await axios.post(`applicationsetting/initialsetup/create`, data);
       return response;

    } catch (error) {
        return error
    }
}

export async function getEnquiryStages(data: any) {
    try {
        return await axios.post(`enquirystage/getEnquiryStages`, data);
    } catch (error) {
        return error;
    }
}

export async function editEnquiryStage(data: any, id : string) {
    try {
        return await axios.put(`enquirystage/update/${id}`, data);
    } catch (error) {
        return error;
    }
}


// API : v1/alumni/create
export async function newAlumniEnrollment(data) {
    try {
        return await axios.post(`alumni/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/alumni/createBulk
export async function currentAlumniEnrollment(data) {
    try {
        return await axios.post(`alumni/createBulk`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/alumni/getStudents
export async function getAlumniStudents(data) {
    try {
        return await axios.post(`alumni/getStudents`, data);
        
    } catch (error) {
        return error;
    }
}

// ENQUIRY CAMPAIGN

// API : v1/enquirycampaign/create
export async function createLeads(data) {
    try {
        return await axios.post(`enquirycampaign/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaign/update/id
export async function updateLeads(data:any,id:any) {
    try {
        return await axios.put(`enquirycampaign/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaign/update
export async function assignLeads(data) {
    try {
        return await axios.put(`enquirycampaign/assign`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaign/getEnquiries
export async function getLeads(data) {
    try {
        return await axios.post(`enquirycampaign/getEnquiries`, data);
        
    } catch (error) {
        return error;
    }
}
// API : v1/enquirycampaign/delete
export async function deleteLeads(data, id) {
    try {
        return await axios.put(`enquirycampaign/delete/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaign/getEnquiries/id
export async function getLeadsById(id) {
    try {
        return await axios.post(`enquirycampaign/getEnquiry/${id}`);
        
    } catch (error) {
        return error;
    }
}

// API : /enquirycampaign/createFollowUp
export async function createLeadsFollowUp(body) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        return await axios.post(`/enquirycampaign/createFollowUp`,body);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaign/getEnquiries/id
export async function getLeadsFollowupHistory(id) {
    try {
        return await axios.get(`enquirycampaign/getFollowUp/${id}`);
        
    } catch (error) {
        return error;
    }
}
// API : v1/enquiryotp/sendotp
export async function sendEnquiryOtp(data) {
    try {
        return await axios.post(`enquiryotp/sendotp`, data);
        
    } catch (error) {
        return error;
    }
}
// API : v1/enquiryotp/verifyotp
export async function verifyEnquiryOtp(data) {
    try {
        return await axios.post(`enquiryotp/verifyotp`, data);
        
    } catch (error) {
        return error;
    }
}

// Bulk Upload APIs starts here //

// API : v1/student/bulkupload
export async function studentsBulkUpload(data: any) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`student/bulkupload`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}

// API : v1/student/validatebulk
export async function studentsBulkUploadValidation(data: any) {
    try {
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`student/validatebulk`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}

// API : v1/enquirycampaign/bulkEnquiry
export async function leadsBulkUpload(data: any) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`enquirycampaign/bulkEnquiry`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}

// API : v1/enquirycampaign/validateEnquiry
export async function leadsBulkUploadValidation(data: any) {
    try {
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`enquirycampaign/validateEnquiry`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}


// API : v1/enquiry/bulkEnquiry
export async function enquiryBulkUpload(data: any) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`enquiry/bulkEnquiry`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}

// API : v1/enquiry/validateEnquiry
export async function enquiryBulkUploadValidation(data: any) {
    try {
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`enquiry/validateEnquiry`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}

// Bulk Upload APIs ends here //

// Leads Number Settings API starts here //

// API : v1/enquirycampaignnumbersettings/create
export async function addLeadsNumberSettings(data) {
    try {
        return await axios.post(`enquirycampaignnumbersettings/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaignnumbersettings/getAllSettings
export async function getLeadsNumberSettings(data) {
    try {
        return await axios.post(`enquirycampaignnumbersettings/getAllSettings`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaignnumbersettings/update/id
export async function editLeadsNumberSettings(data:any,id:any) {
    try {
        return await axios.put(`enquirycampaignnumbersettings/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirycampaignnumbersettings/delete/id
export async function deleteLeadsNumberSettings(id) {
    try {
        return await axios.delete(`enquirycampaignnumbersettings/delete/${id}`);
        
    } catch (error) {
        return error;
    }
}

// Leads Number Settings API ends here //

// Leads Number Settings API starts here //

// API : v1/enquirynumbersettings/create
export async function addEnquiryNumberSettings(data) {
    try {
        return await axios.post(`enquirynumbersettings/create`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirynumbersettings/getAllSettings
export async function getEnquiryNumberSettings(data) {
    try {
        return await axios.post(`enquirynumbersettings/getAllSettings`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirynumbersettings/update/id
export async function editEnquiryNumberSettings(data:any,id:any) {
    try {
        return await axios.put(`enquirynumbersettings/update/${id}`, data);
        
    } catch (error) {
        return error;
    }
}

// API : v1/enquirynumbersettings/delete/id
export async function deleteEnquiryNumberSettings(id) {
    try {
        return await axios.delete(`enquirynumbersettings/delete/${id}`);
        
    } catch (error) {
        return error;
    }
}

// Leads Number Settings API ends here //

// ADMISSION CRM

// API : v1/tasknumbersettings/create
export async function createTaskSettings(data) {
    try {
        return await axios.post(`tasknumbersettings/create`, data);
    } catch (error) {
        return error;
    }
}   
// API : v1/tasknumbersettings/delete/id
export async function deleteTaskSettings(id) {
    try {
        return await axios.delete(`tasknumbersettings/delete/${id}`)
    } catch (error) {
        return error;
    }
}   
// API : v1/tasknumbersettings/getAllSettings
export async function getTaskSettings(data) {
    try {
        return await axios.post(`tasknumbersettings/getAllSettings`, data);
    } catch (error) {
        return error;
    }
}
// API : enquirycampaign/task/admin/web/${id}
export async function getTasksByEnquiryId(id) {
    try {
        return await axios.post(`enquirycampaign/task/admin/web/${id}`);    
    } catch (error) {
        return error;
    }
}

// CALL HISTORY API

export async function getStaffCallLogs(data) {
    try {
        return await axios.post(`enquirycampaign/admin/web/getStaffCallLogs`,data);    
    } catch (error) {
        return error;
    }
}

export async function getStaffCallSummary(data) {
    try {
        return await axios.post(`enquirycampaign/admin/web/getStaffCallSummary`,data);    
    } catch (error) {
        return error;
    }
}

// CALL HISTORY API ENDS HERE

// LIVE TRACKING REPLAY
export async function getLiveTrackingReplay(data) {
    try {
        return await axios.post(`enquirycampaign/admin/web/liveTracking/getLocation`,data);    
    } catch (error) {
        return error;
    }
}

// ADMISSION CRM END

// STREAMS
// API: v1/streams/getStreams
export async function getStreams(data) {
    try {
        return await axios.post(`orgstream/getOrgStreams`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/streams/create
export async function createStream(data) {
    try {
        return await axios.post(`orgstream/create`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/streams/update/:id
export async function updateStream(data, id) {
    try {
        return await axios.put(`orgstream/update/${id}`, data);

    } catch (error) {
        return error;
    }
}
// API: v1/streams/delete/:id
export async function deleteStream(id) {
    try {
        return await axios.delete(`orgstream/delete/${id}`);

    } catch (error) {
        return error;
    }
}

// API : v1/student/bulkUpdate
export async function studentsBulkUpdate(data: any) {
    try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const config: any = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`student/bulkUpdate`, data, config);
        
    } catch (error) {
        return error?.response;
    }
}