import React from "react";
import { useSelector } from "react-redux";

import AlertItem from "./AlertItem";
import { RootState } from "redux/reducers";
import { IAlertState } from "redux/types/alert";


const Alert: React.FC = (): JSX.Element | null => {
  const alerts = useSelector((state: RootState) => state.alert);
  return alerts.length ? (<>
    < div style={{
      position: "absolute",
      bottom: "5rem",
      right: "2rem",
      // Increase the third value in clamp to set a larger maximum width on bigger screens.      
      width: "clamp(10rem, 20%, 16rem)",
      zIndex: "10000",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
    }}>
      {
        alerts.map((alert: IAlertState) => (
          <AlertItem data={alert} key={alert.id} />
        ))
      }
    </div >
  </>) : null;
};

export default Alert;
