import React, { useEffect, useState,useMemo } from "react";
import {
  Table,
} from "react-bootstrap";
import useApi from "components/hooks/useApi";
import TablePlaceholder from "components/shared/TablePlaceholder";
import EmptyPlaceholder from 'components/shared/emptyPlaceholder';
import {getAttendanceRecord} from "services/studentInfo";
import {studentProfileMonthAttendance} from "services/attendance"

const AttendanceRecord = ({ profileData }) => {
  const orgId = localStorage.getItem("orgId");
  const branchId = localStorage.getItem("branchId");
  const branchAcademicBatchId = localStorage.getItem("branchAcademicBatchId");

  const [viewAttendanceDetails, setViewAttendanceDetails] = useState(null);

  const {
    action: getRecords,
    data: records,
    loading: isLoading,
  } = useApi(getAttendanceRecord);
  const {
    action: getMonthsRecords,
    data: monthRecords,
    loading: isMonthRecordLoading,
  } = useApi(studentProfileMonthAttendance);

  const attendanceRecordList = useMemo(() => {
    if (!records?.data?.dashboardData) {
      setViewAttendanceDetails(null);
      return [];
    }
    let temp = {};
    records?.data?.dashboardData?.map((item, i) => {
      return (temp[i] = { show: false });
    });
    setViewAttendanceDetails(temp);
    return records?.data?.dashboardData;
  }, [records?.data]);

  const [attendanceMonthList, setAttendanceMonthList] = useState([]);

  useEffect(() => {
    if (monthRecords?.data?.records?.length) {
      setAttendanceMonthList(monthRecords?.data?.records);
    }
  }, [monthRecords?.data?.records]);

  useEffect(() => {
    if (
      profileData?.academicClassId?._id &&
      profileData?.academicSectionId?._id
    ) {
      getRecords({
        studentBranchAcademicYearId: profileData?._id,
        branchId: branchId,
        branchAcademicBatchId: branchAcademicBatchId,
        academicClassId: profileData?.academicClassId?._id,
        academicSectionId: profileData?.academicSectionId?._id,
      });
    }
  }, [profileData]);

  return (
    <div>
      {isLoading ? (
        <TablePlaceholder noOfCopies={10} />
      ) : attendanceRecordList && attendanceRecordList.length ? (
        <Table className="theme-content-table" responsive>
          <thead>
            <tr>
              <th>Month</th>
              <th>Working Days</th>
              <th>Holidays</th>
              <th>Present</th>
              <th>Absent</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {attendanceRecordList.map((item: any, index: any) => (
              <>
                <tr key={index}>
                  <td className="fw-bold">{`${item?.monthName} ${item?.year}`}</td>
                  <td>{item?.totalWorkingDays}</td>
                  <td>{item?.noOfHolidays}</td>
                  <td>{item?.noOfPresentDays}</td>
                  <td>
                    <span style={{ color: "#DC1C1C" }}>
                      {item?.noOfAbsentDays}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between ">
                      <div
                        onClick={async () => {
                          const monthIndex = new Date(
                            `${item?.monthName} 1, ${item?.year}`
                          ).getMonth();

                          const firstDay = new Date(
                            item?.year,
                            monthIndex,
                            1
                          ).toISOString();
                          const lastDay = new Date(
                            item?.year,
                            monthIndex + 1,
                            0
                          ).toISOString();
                          if (!viewAttendanceDetails?.[index]?.show) {
                            await getMonthsRecords({
                              studentBranchAcademicYearId: profileData?._id,
                              branchId: branchId,
                              branchAcademicBatchId: branchAcademicBatchId,
                              academicClassId:
                                profileData?.academicClassId?._id,
                              academicSectionId:
                                profileData?.academicSectionId?._id,
                              isDayBoarded: false,
                              fromDate: firstDay,
                              toDate: lastDay,
                            });
                          } else {
                            setAttendanceMonthList([]);
                          }

                          //   setViewAttendanceDetails({
                          //   ...viewAttendanceDetails,
                          //   [index]: {
                          //     show: !viewAttendanceDetails?.[index].show,
                          //   },
                          // });

                          let temp = {};
                          attendanceRecordList?.map((item, i) => {
                            if (index === i) {
                              return (temp[i] = {
                                show: !viewAttendanceDetails?.[i].show,
                              });
                            }
                            return (temp[i] = { show: false });
                          });
                          setViewAttendanceDetails(temp);
                        }}
                        className={
                          viewAttendanceDetails?.[index]?.show
                            ? "rotate-img-180"
                            : "rotate-img-0"
                        }
                      >
                        <img
                          className="ms-2"
                          src="/images/down-arrow-false.svg"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  key={index ? index + new Date().toLocaleString() : ""}
                  style={{
                    display: viewAttendanceDetails?.[index]?.show ? "" : "none",
                    width: "100%",
                  }}
                  className={item.check ? "no-hover-row fields" : "fields"}
                >
                  <td colSpan={12}>
                    <div className="table-fixed-height"></div>
                    {isMonthRecordLoading ? (
                      <TablePlaceholder noOfCopies={10} />
                    ) : attendanceMonthList && attendanceMonthList.length ? (
                      <Table responsive className="theme-content-table">
                        <tbody>
                          {attendanceMonthList.map((dateItem: any, i: any) => (
                            <tr style={{ backgroundColor: "#F7F9FB" }}>
                              <td>
                                <span className="d-flex justify-content-center fw-bold">
                                  {dateItem?.dayMonth}
                                </span>
                              </td>
                              <td>
                                <span className="d-flex justify-content-center">
                                  {dateItem?.day}
                                </span>
                              </td>
                              <td>
                                <span className="d-flex justify-content-center mt-2">
                                  <div
                                    className="studentProfileAttendanceStatusBtn"
                                    style={{
                                      backgroundColor:
                                        dateItem?.status === "Absent"
                                          ? "#DC1C1C"
                                          : dateItem?.status === "Present"
                                          ? "#0F9936"
                                          : "#088D8D",
                                    }}
                                  >
                                    {dateItem?.status}
                                  </div>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <EmptyPlaceholder />
                    )}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyPlaceholder />
      )}
    </div>
  );
};

export default AttendanceRecord;