import types from "redux/actions/types";
import { INotificationsState, NotificationsActions } from "../types/notifications";
import { INotifications } from "redux/types/notifications";

const initialState: INotificationsState = {
  loading: true,
  count: 0,
  unreadCount: 0,
  notification: {} as INotifications,
  notifications: [] as INotifications[],
};

const notificationsReducer = (state = initialState, action: NotificationsActions) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS:

      return {
        ...state,
        loading: false,
        count: action.payload.count,
        notifications: action.payload.notification as INotifications[],
        unreadCount: action.payload.unreadCount,

      };
    case types.UPDATE_READ_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        count: 0,
      };

    default:
      return state;
  }
};
export default notificationsReducer;
