import { Dispatch } from "redux";
import axios from "../../utils/axios";
import { setClientDetailsInHeaders, setUserAuthToken } from "../../utils/headers";
import types from "../actionTypes/user";
import ActionTypes from "./types";
import { UserActions } from "redux/types/user";
import { setAlert } from "redux/actions/alert";
import { datadogRum } from "@datadog/browser-rum";

// LOAD USER
export const loadUser =
  (guestRoleId: any) => async (dispatch: Dispatch<UserActions>) => {
    if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
    if (localStorage.use_role_level) localStorage.removeItem("use_role_level");
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };
    const params = new URLSearchParams({
      guestRoleId: guestRoleId,
    }).toString();
    let url;
    if (guestRoleId) url = "user/auth-user?" + params;
    else url = "user/auth-user";

    try {
      const { data } = await axios.get(url, config);
      if(data){
        datadogRum.setUser({
          id: data._id,
          name: data.username || data.email || 'unknown_user',
          email: data.email || 'unknown_email',
          schoolName: data?.organization?.[0]?.account_name || 'unknown_school',
        })
      }
      dispatch({
        type: types.USER_LOADED,
        payload: data
      });
    } catch (error) {
      dispatch({ type: types.USER_AUTH_ERROR });
    }
  };

// LOGIN USER
export const loginUser =
  (body: any, navigate: any) => async (dispatch: Dispatch<UserActions>) => {
    const config = await setClientDetailsInHeaders('default')
    
    try {
      const { data } = await axios.post(`user/login`, { ...body, deviceType: "WebApp" }, config);
      dispatch({
        type: types.USER_LOGIN_SUCCESS,
        payload: data,
      });
      dispatch<any>(
        setAlert({
          msg: "Login Successful!",
          status: 200,
          alertType: "success",
        })
      );
      dispatch<any>(loadUser(null));
      if (localStorage.isPasswordRest) {
        navigate("/reset_password");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      dispatch({ type: types.USER_LOGIN_FAIL });
      if (error?.response?.data?.info) {
        dispatch<any>(
          setAlert({
            msg: error?.response?.data?.message ?? "Invalid Username/Password",
            status: 500,
            alertType: "danger",
          })
        );
      } else {
        dispatch<any>(
          setAlert({
            msg: "Invalid Username/Password",
            status: 500,
            alertType: "danger",
          })
        );
      }
    }
  };
export const switchUserAccount =
  (body: any, navigate?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config = await setClientDetailsInHeaders('default')
    
    try {
      const { data } = await axios.post(`user/switchAccount`, { ...body, deviceType: "WebApp" }, config);
      dispatch({
        type: ActionTypes.USER_SWITCH_SUCCESS,
        payload: data,
      });
      dispatch<any>(
        setAlert({
          msg: "Switch Successful!",
          status: 200,
          alertType: "success",
        })
      );
      dispatch<any>(loadUser(null));
      navigate("/dashboard");
    } catch (error) {
      console.log(error)
      dispatch({ type: ActionTypes.USER_SWITCH_FAIL });
      if (error?.response?.data?.message) {
        dispatch<any>(
          setAlert({
            msg: error?.response?.data?.message ?? "Invalid Username/Password",
            status: 500,
            alertType: "danger",
          })
        );
      } else {
        dispatch<any>(
          setAlert({
            msg: "Invalid Username/Password",
            status: 500,
            alertType: "danger",
          })
        );
      }
    }
  };

// REGISTER USER
export const registerUser =
  (body: any, setSubmitting: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`user/register`, body, config);
        dispatch({
          type: types.USER_REGISTER_SUCCESS,
          payload: data,
        });


      } catch (error) {
        dispatch({ type: types.USER_REGISTER_FAIL });
      } finally {
        setSubmitting(false);
      }
    };

export const registerStaffdetails =
  (body: any, setSubmitting: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`user/create/staff`, body, config);
        dispatch({
          type: types.USER_CREATION_SUCCESS,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Staff Created successfully !!!!!!",
            status: 200,
            alertType: "success",
          })
        );

      } catch (error) {
        dispatch({ type: types.USER_REGISTER_FAIL });
        dispatch<any>(
          setAlert({
            msg: error?.response?.data?.message ?? "Something went wrong when creating staff",
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
    };

// GET USERS
export const getUsers = () => async (dispatch: Dispatch<UserActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`user/users`, config);
    dispatch({ type: types.GET_USERS, payload: data });
  } catch (error) { }
};
// GET USERDETAILS
export const getUserdetails = () => async (dispatch: Dispatch<UserActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`user/users`, config);
    dispatch({ type: types.GET_USERDETAILS, payload: data });
  } catch (error) { }
};

// GET USER PERMISSIONS
export const getUserPermissions = (obj: any, setPreviousRole: any) => async (dispatch: Dispatch<UserActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.post(`user/getpermission`, obj, config);
    dispatch({ type: types.USER_PERMISSIONS_LOADED, payload: data });
  } catch (error) {
    setPreviousRole();
    dispatch<any>(
      setAlert({
        msg: "Permissions not assigned to this role",
        status: 500,
        alertType: "danger",
      })
    );
  }
};

// UPDATE USER DATA
export const updateUser =
  (body: any, id: any, setSubmitting: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.patch(`user/users/${id}`, body, config);
        dispatch({
          type: types.UPDATE_USER,
          payload: data,
        });
        dispatch<any>(getUsers());
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    };


// export const updateStaff =
//   (body: any, id: any, setSubmitting: any) =>
//     async (dispatch: Dispatch<UserActions>) => {
//       const config: any = {
//         header: {
//           "Content-Type": "application/json",
//         },
//       };

//       try {
//         const { data } = await axios.put(`user/update/${id}`, body, config);
//         dispatch({
//           type: types.UPDATE_USER,
//           payload: data,
//         });
//         dispatch<any>(
//           setAlert({
//             msg: "Updating Student Successful",
//             status: 200,
//             alertType: "success",
//           })
//         );
//         // dispatch<any>(getUsers());
//       } catch (error) {
//       } 
//       finally {
//         if (setSubmitting)
//           setSubmitting(false);
//       }
//     };

export const updateStudent =
  (body: any, id: any, setSubmitting: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.put(`student/update/${id}`, body, config);
        dispatch({
          type: types.UPDATE_USER,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Updating Student Successful",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getUsers());
      } catch (error) {
      }
      finally {
        if (setSubmitting)
          setSubmitting(false);
      }
    };

export const updateBulkStudents =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
        const { data } = await axios.post(`student/bulkstudentupdate`, body, config);
        dispatch({
          type: types.UPDATE_STUDENTS_BULK,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Updating Students Successful",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getUsers());
      } catch (error) {
        const msg = error?.response?.data?.message ?? "Something went wrong when updating students";
        dispatch<any>(setAlert(
          {
            msg: msg,
            status: 500,
            alertType: "danger",
          }
        ))
      }
    };

export const studentsPasswordGeneration =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`student/bulkresetpassword`, body, config);
        dispatch({
          type: types.BULK_STUDENT_PASSWORD_GENERATION,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Password Generation Successful",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getUsers());
      } catch (error) {
        dispatch<any>(setAlert(
          {
            msg: "Something went wrong when generating passwords for students",
            status: 500,
            alertType: "danger",
          }
        ))
      }
    };
// STUDENT INDIVIDUAL PASSWORD GENERATION

export const singlestudentsPassword =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`student/updatestudentspassword`, body, config);
        dispatch({
          type: types.BULK_STUDENT_PASSWORD_GENERATION,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Password Generation Successful",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getUsers());
      } catch (error) {
        dispatch<any>(setAlert(
          {
            msg: "Something went wrong when generating passwords for students",
            status: 500,
            alertType: "danger",
          }
        ))
      }
    };

// DELETE USER
export const deleteUser =
  (id: number) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.delete(`user/users/${id}`, config);
      dispatch({ type: types.DELETE_USER, payload: id });
    } catch (error) { }
  };
// LOGOUT USER
export const logOutUser =
  (navigate: any) => (dispatch: Dispatch<UserActions>) => {
    dispatch({ type: types.USER_LOGOUT });
    dispatch<any>({ type: ActionTypes.LOGOUT_PROFILE });
    dispatch<any>({ type: ActionTypes.LOGOUT_ORGANIZATION });
    dispatch<any>({ type: ActionTypes.LOGOUT_BRANCH });
    navigate("/dashboard");
  };

export const getStudentsandStaff =
  (body: any, setIsLoading?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`student/getStudents`, body, config);
      dispatch({ type: types.GET_USERDETAILS, payload: data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the getusers!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading)
        setIsLoading(false);
    }
  };
export const getFeePlanStudents =
  (body: any, setIsLoading?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`student/getFeePlanStudents`, body, config);
      dispatch({ type: types.GETFEEPLAN_STUDENTS, payload: data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the getusers!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

export const getFeeTypeStudents =
  (body: any, setIsLoading?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`student/getFeeTypeStudents`, body, config);
      dispatch({ type: types.GETFEETYPE_STUDENTS, payload: data });
    } catch (error: any) {

      dispatch<any>(
        setAlert({
          msg: error.response.data.message,
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

export const searchStudents =
  (body: any, setIsLoading?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (setIsLoading) {
        setIsLoading(true);
      }
      const { data } = await axios.post(`student/search`, body, config);
      dispatch({ type: types.SEARCH_USERDETAILS, payload: data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the getusers!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading)
        setIsLoading(false);
    }
  };


export const getstaffDetails =
  (body: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`user/getStaff`, body, config);
      dispatch({ type: types.GET_STAFFDETAILS, payload: data.data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the getusers!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    }
  };
export const getStudent =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`student/getStudent`, body, config);
        dispatch({
          type: types.GET_STUDENT,
          payload: data,
        });


      } catch (error: any) {
        dispatch<any>(
          setAlert({
            msg: "Something went wrong when fetching the getusers!",
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
    };

export const getStudentJourney =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {

      dispatch({
        type: types.GET_STUDENT_JOURNEY_REQUEST,
      });

      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`student/getJourney`, body, config);
        dispatch({
          type: types.GET_STUDENT_JOURNEY,
          payload: data?.data,
        });


      } catch (error: any) {
        dispatch({
          type: types.GET_STUDENT_JOURNEY_FAILURE,
        });
        const message = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong when getting the Journey!";
        dispatch<any>(
          setAlert({
            msg: message,
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
    };

// Staff

export const getstaff =
  (body: any, setIsLoading?: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`staff/getstaff`, body, config);
      dispatch({ type: types.GET_STAFFDETAILS, payload: data.data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when getting the staffs!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading)
        setIsLoading(false);
    }
  };
export const filterstaff =
  (body: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`staff/filterstaff`, body, config);

      dispatch({ type: types.FILTER_STAFFDETAILS, payload: data.data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when filtering the staffs!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    }
  };

export const updatestaff =
  (id: any, body: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.put(`staff/updatestaff/${id}`, body, config);
      dispatch({ type: types.UPDATE_STAFFDETAILS, payload: data.data });
      dispatch<any>(
        setAlert({
          msg: " staff details updated successfully",
          status: 200,
          alertType: "success",
        })
      );
    } catch (error: any) {      
      dispatch<any>(
        setAlert({
          msg: error?.response?.data?.message ?? "Something went wrong when updating staff",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    }
  };

export const updateBulkStaff =
  (body: any, setIsLoading: any) => async (dispatch: Dispatch<UserActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(`staff/updatebulk`, body, config);
      dispatch({ type: types.UPDATE_STAFFDETAILS, payload: data.data });
      dispatch<any>(
        setAlert({
          msg: " staff details updated successfully",
          status: 200,
          alertType: "success",
        })
      );
    } catch (error: any) {
      // dispatch<any>(
      //   setAlert({
      //     msg: "Something went wrong when updating staff details!",
      //     status: error?.response?.status,
      //     alertType: "danger",
      //   })
      // );
    }
    finally {
      if (setIsLoading)
        setIsLoading(false);
    }
  };

export const userPasswordGeneration =
  (body: any) =>
    async (dispatch: Dispatch<UserActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`user/bulkresetpassword`, body, config);
        dispatch({
          type: types.BULK_USER_PASSWORD_GENERATION,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "Password Generation Successful",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getUsers());
      } catch (error) {
        dispatch<any>(setAlert(
          {
            msg: "Something went wrong when generating passwords for students",
            status: 500,
            alertType: "danger",
          }
        ))
      }
    };
