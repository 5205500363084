import axios from "utils/axios";

export async function addVisitor(data) {
    try {
        return await axios.post(`superuser/login`, data);

        
    } catch (error) {
        return error;
    }
}


// API: v1/student/attendanceSearchMulti
export async function getStudentAttendenceReports(data) {
    try {
        return await axios.post(`student/attendanceSearchMulti`, data);

    } catch (error) {
        return error;
    }
}

// API: v1/student/attendanceSearch
export async function getStudentAttendance(data) {
    try {
        return await axios.post(`student/attendanceSearch`, data);

    } catch (error) {
        return error;
    }
}

export async function ValidateAttendanceBulkUpload(data) {
    try {
        return await axios.post(`scholasticmarks/bulkUpload/validate/attendanceForReportCard`, data);

    } catch (error) {
        return error;
    }
}

export async function attendanceBulkUpload(data) {
    try {
        return await axios.post(`scholasticmarks/bulkAttendanceForReportCard`, data);

    } catch (error) {
        return error;
    }
}

// API : v1/holiday/map-classes
export async function mapClassesToHolidays(data) {
    try {
        return await axios.post(`holiday/map-classes`, data);

    } catch (error) {
        return error;
    }
}

export async function studentProfileMonthAttendance(data) {
    try {
        return await axios.post(`sessionattendance/studentProfileMonthAttendance`, data);

    } catch (error) {
        return error;
    }
}