import React, { TextareaHTMLAttributes } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { InputFormat } from "utils/helper/types";

export type TTextInput = Omit<FormControlProps, "onChange"> &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string; // Label for the input
    type?: "text" | "number"; // Type of input
    format?: InputFormat; // Custom format to control special char behavior
    validationPattern?: RegExp; // Allow custom regex patterns for validation
    validationMessage?: string; // Custom message to display on validation failure
    maxLength?: number; // Maximum length of input
    minLength?: number; // Minimum length of input
    min?: number | string; // Minimum value for number input
    max?: number | string; // Maximum value for number input
    inputMode?: "text" | "numeric" | "email" | "tel"; // Input mode for keyboard type
    required?: boolean; // Is the field required? (only for visual use the yup validator)
    enableAI?: boolean; // is AI enabled?
    onPrompt?: () => void;
    showError?: boolean; // Whether to show an error message
    highlightOnValue?: boolean; // Whether to highlight the input value if present enabled by default.
    step?: string; // To enable to enter decimal values for type number
  };


  const regexPatterns = {
    number: /^[-+eE]$/, // Block these characters in number inputs
    chars: /[^a-zA-Z0-9 ]/, // Block special characters, allow only letters, numbers, and spaces
    charsWithoutNum: /[^a-zA-Z ]$/, // Block numbers and special characters
    email: /[^a-zA-Z0-9@._-]/, // Allow valid email characters only
  };

const TextInput = React.forwardRef<HTMLInputElement, TTextInput>(
  (
    {
      enableAI,
      onPrompt,
      type,
      required,
      format,
      label,
      showError,
      highlightOnValue = true,
      validationMessage,
      validationPattern,
      step,
      ...control
    },
    ref
  ) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      // VALIDATIONS
      if (type === "number" && regexPatterns.number.test(e.key)) {
        e.preventDefault(); // Block special characters if number
      }

      if ((!type || type === "text") && format === "CHARS" && regexPatterns.chars.test(e.key)) {
        e.preventDefault(); // Block special characters if format is "CHARS"
      }

      if ((!type || type === "text") && format === "CHARS_WITHOUT_NUM" && regexPatterns.charsWithoutNum.test(e.key)) {
        e.preventDefault(); // Block non-alphabetic and non-space input if format is "CHARS_WITHOUT_NUM"
      }
    
      if ((!type || type === "text") && format === "EMAIL" && regexPatterns.email.test(e.key)) {
        e.preventDefault(); // Block input thats no a valid email
      }
      
      // PATTERN MATCHING
      if (validationPattern && !validationPattern.test(e.key)) {
        e.preventDefault(); // Block input that doesn't match the regex pattern
      }
      

      control.onKeyDown?.(e as any);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      // CONVERSIONS
      // Convert to upper case
      if ((!type || type === "text") && format === "UPPER") {
        e.currentTarget.value = e.currentTarget.value.toUpperCase();
      }
      // Convert to lower case
      if ((!type || type === "text") && format === "LOWER") {
        e.currentTarget.value = e.currentTarget.value.toLowerCase();
      }
      // Convert to title case
      if ((!type || type === "text") && format === "TITLE") {
        e.currentTarget.value = e.currentTarget.value
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
      // Initial Letter Capital
      if ((!type || type === "text") && format === "CAPS") {
        e.currentTarget.value = e.currentTarget.value
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());
      }

      control.onInput?.(e as any);
    };

    return (
      <>
        {label && (
          <Form.Label htmlFor={control.id}>
            {label} {required && <span className="text-danger">*</span>}
          </Form.Label>
        )}
        <div className="position-relative">
          <Form.Control
            {...control}
            type={type}
            ref={ref}
            onKeyDown={(e: any) => handleKeyDown(e)}
            onInput={(e: any) => handleInput(e)}
            isInvalid={showError}
            className={
              highlightOnValue
                ? "theme-input-control " + control.className + " filled-field"
                : "theme-input-control " + control.className
            }
            placeholder={
              control.placeholder ||
              (format === "CHARS" ? "No special characters allowed" : "")
            }
            step={step}
          />
          {enableAI && onPrompt && (
            <img
              src="/images/ai-input.svg"
              onClick={() => onPrompt()}
              width={27}
              height={27}
              className="position-absolute cursor-pointer"
              style={{ right: "10px", bottom: "3%" }}
            />
          )}
        </div>
        {showError && (
          <Form.Text className="text-danger">
            {validationMessage || "Invalid input"}
          </Form.Text>
        )}
      </>
    );
  }
);

export default React.memo(TextInput);
